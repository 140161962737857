import {post} from "./common";
import * as querystring from "querystring";

export const user_verify = process.env.REACT_APP_USER;
export const pass_verify = process.env.REACT_APP_PASS;

export const getToken = () => {
    const hash = btoa(`${user_verify}:${pass_verify}`)
    return post({
        url: "/auth/token",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            'Authorization': `Basic ${hash}`
        },
        params: querystring.stringify({"grant_type": "client_credentials"})
    });
}

export const getInfo = (refNo:any, sessionId: any) => {
    return post({
        url: "/retail-look-backms/recap/getRecapOneYear",
        headers: {
            "Authorization": `Bearer ${sessionStorage.getItem('access_token')}`,
            "Content-Type": "application/json;charset=UTF-8",
        },
        params:{
            "refNo": refNo,
            "sessionId": sessionId
        }
    });
}
