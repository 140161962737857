import React, { Fragment } from 'react'
import { closeWebview } from '../../api/common'
import icon_close_customer from '../../assets/images/icon_close_customer.svg'
import { TIME_ANIMATION, TIME_ANIMATION_NEXT_PAGE } from './BirthDayCustomer'

interface IDotSlide {
    dataSlide: any[],
    setValueCurrent: React.Dispatch<React.SetStateAction<any>>
    setShowSlideBirthDay: React.Dispatch<React.SetStateAction<any>>
    setValueCurrentNext: React.Dispatch<React.SetStateAction<any>>

    statePause: boolean
    activeAnimationWhenChangeDot: any
    valueCurrent: any
}
export default function DotSlide({ dataSlide, setValueCurrent, statePause, setShowSlideBirthDay, activeAnimationWhenChangeDot, valueCurrent, setValueCurrentNext }: IDotSlide) {

    return (
        <>
            <div className='absolute w-100' style={{ top: '5%', zIndex: 6 }}>
                <div className="pause">
                    <div className="progress-container">
                        {
                            Object.entries(dataSlide).map((itemMap: any, key) => {
                                return <Fragment key={itemMap?.[1]?.[0]?.index}>

                                    <li
                                        // value={`${itemMap[1][0]}-${itemMap[1][1]}`}
                                        value={`${itemMap?.[1]?.[0]?.index}-${itemMap?.[1]?.[0]?.name}`}
                                        onClick={() => {
                                            if (itemMap?.[1]?.[0]?.index == valueCurrent?.split('-')[0]) return
                                            const progress = Array.from(document.querySelectorAll('.progress'));
                                            activeAnimationWhenChangeDot('', progress[key].getAttribute('value'))

                                        }} style={{
                                            animationPlayState: statePause ? 'running' : 'paused',
                                            animationDuration: `${TIME_ANIMATION_NEXT_PAGE / 1000}s`,
                                            height: '2px',
                                            // padding:'12px 0px'
                                        }}
                                        className={`progress ${key == 0 ? 'active' : ''}`}>
                                    </li>

                                </Fragment>
                            })
                        }
                    </div>
                </div>
                <img src={icon_close_customer} className='absolute' style={{ top: '10%', right: '12px', zIndex: 6, marginTop: '24px' }}
                    onClick={() => {
                        closeWebview()
                        setShowSlideBirthDay(false)
                    }} />
            </div>
        </>
    )
}
