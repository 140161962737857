import React, { useState } from "react";
import { useLongPress, LongPressDetectEvents } from "use-long-press";
interface IControlSlide {
  statePause: boolean;
  setValueCurrent: React.Dispatch<React.SetStateAction<any>>;
  setStatePause: React.Dispatch<React.SetStateAction<any>>;
  activeAnimationWhenChangeDot: any;
}
export default function ControlsSlide({
  statePause,
  setValueCurrent,
  setStatePause,
  activeAnimationWhenChangeDot,
}: IControlSlide) {
  const [checkLongPres, setCheckLongPress] = useState(true); //Kiểm tra xem người dùng đang giữ màn có trong khoảng thời gian là 150 ms ko

  const handleActionPre = () => {
    const progress = Array.from(document.querySelectorAll(".progress"));
    const progressActive = Array.from(
      document.querySelectorAll(".progress.active")
    );
    for (var i = 0; i < progress.length; i++) {
      if (progress[i].className.includes("active")) {
        if (i === 0) {
          return;
        } else {
          activeAnimationWhenChangeDot("PRE", progress[i]?.previousElementSibling?.getAttribute('value'));
          // progress.map((itemMap1) => {
          //   itemMap1.classList.remove("active");
          //   itemMap1.classList.remove("passed");
          // });
          // for (var j = 0; j <= i - 1; j++) {
          //   progress[j].classList.add("passed");
          // }
        }
      }
    }
  };
  const handleActionNext = () => {
    const progress = Array.from(document.querySelectorAll(".progress"));
    for (var i = 0; i < progress.length - 1; i++) {
      if (progress[i].className.includes("active")) {
        if (i === progress.length - 1) {
          return;
        } else {
          activeAnimationWhenChangeDot("NEXT", progress[i]?.nextElementSibling?.getAttribute('value'));
          // progress.map((itemMap1) => {
          //   itemMap1.classList.remove("active");
          //   itemMap1.classList.remove("passed");
          // });
          // for (var j = 0; j <= i; j++) {
          //   progress[j].classList.add("passed");
          // }
        }
      }
    }
  };
  const [enabled, setEnabled] = React.useState(true); //kiểm tra xem react có đang dùng hook hay ko
  const commonOptionsUseLongPress = {
    threshold: 150,
    captureEvent: true,
    cancelOnMovement: false,
    detect: LongPressDetectEvents.BOTH,
  };
  const commonFuncOnStart = () => {
    setStatePause(false);
    handleAddClass();
  }
  const commonFunctionOnFinish = () => {
    setStatePause(true);
    setTimeout(() => {
      setCheckLongPress(true);
    }, 150);
  }
  const commonFunctionOnCancel = () => {
    setStatePause(true);

  }
  const callback = React.useCallback(() => {
    setStatePause(false);
    setCheckLongPress(false);
  }, []);
  const handleAddClass = () => {
    const itemSlideText = Array.from(
      document.querySelectorAll(".animate-charcter")
    );
    itemSlideText.map((el) => {
      el?.classList.add("animate-charcter-animation");
    });
  };
  const bindPre = useLongPress(enabled ? callback : null, {
    onStart: (state) => {
      commonFuncOnStart()
    },
    onFinish: (state) => {
      commonFunctionOnFinish()
    },
    onCancel: (state) => {
      commonFunctionOnCancel()
    },
    ...commonOptionsUseLongPress,
  });
  const bindNext = useLongPress(enabled ? callback : null, {
    onStart: (state) => {
      commonFuncOnStart()
    },
    onFinish: (state) => {
      commonFunctionOnFinish()
    },
    onCancel: (state) => {
      commonFunctionOnCancel()
    },
    ...commonOptionsUseLongPress,
  });
  return (
    <>
      <div
        onClick={() => {
          if (checkLongPres) {
            setStatePause(true);
            handleActionPre();
          }
        }}
        {...bindPre("PRE")}
        className="absolute"
        style={{
          background: "transparent",
          width: "50%",
          zIndex: 5,
          height: "85%",
          bottom: "0px",
        }}
      ></div>

      <div
        onClick={() => {
          if (checkLongPres) {
            setStatePause(true);
            handleActionNext();
          }
        }}
        {...bindNext("NEXT")}
        className="absolute"
        style={{
          background: "transparent",
          width: "50%",
          zIndex: 5,
          height: "85%",
          bottom: "0px",
          right: 0,
        }}
      ></div>
    </>
  );
}
