import icon_celebration_thanks from "../../assets/images/icon_celebration_thanks.svg";
import image_medal_1 from "../../assets/images/icon_medal1_png.png";
import image_medal from "../../assets/images/image_medal.svg";
import icon_medal2 from "../../assets/images/icon_medal2_png.png";
import icon_medal3 from "../../assets/images/icon_medal3_png.png";
import icon_celebration from "../../assets/images/icon_celebration.svg";
import ic_fire_work_thanks from "../../assets/images/ic_fire_work_thanks.svg";
import text_thanks_customer_png from "../../assets/images/text_thanks_customer_png.png";
import icon_medal4 from "../../assets/images/icon_medal4_png.png";
import icon_medal5 from "../../assets/images/icon_medal5_png.png";
import icon_medal6 from "../../assets/images/icon_medal6_png.png";
import icon_medal0 from "../../assets/images/icon_medal0_png.png";

import { formatCurrentComma, GENDER, ZINDEX_RENDER } from "./BirthDayCustomer";

interface IRenderContent {
  valueCurrent: any;
  fakeData: any;
  show: boolean;
  statePause: boolean;
  showContent: boolean;
}
export const one_hundred_million = 100000000;
export const one_billion = 1000000000;
export const fifty_million = 50000000;
export const RULE_Transaction = 123000000;
export const RULE_INSUARANCE = 30000000;
export default function RenderContent({
  valueCurrent,
  fakeData,
  statePause,
  showContent,
}: IRenderContent) {
  const arrImageMedal = [
    icon_medal5,
    icon_medal0,
    icon_medal6,
    icon_medal2,
    image_medal_1,
    icon_medal4,
    icon_medal3,
  ];

  const handleCalculatorFontSize = (valueLength: any) => {
    if (valueLength < 6) {
      return "82px";
    } else if (valueLength >= 6 && valueLength < 10) {
      return "60px";
    } else if (valueLength >= 10 && valueLength <= 15) {
      return "40px";
    } else {
      return "30px";
    }
  };
  const renderTextInvest = (params: any) => {
    let rs = {
      name: "",
      icon: 0,
    };
    if (params.recapInfo.totalSavingInvestmentAmount >= one_billion) {
      if (params?.recapInfo?.gender === GENDER.M) {
        rs.icon = 0;
        rs.name = "Ông Hoàng Tỷ Phú";
      } else if (params?.recapInfo?.gender === "F") {
        rs.icon = 0;
        rs.name = "Thần Tiên Tỷ Tỷ ";

      } else {
        rs.icon = 0;
        rs.name = "Chiến Thần Đầu tư";
      }
    }
    else if (
      params.recapInfo.totalSavingInvestmentAmount >= one_hundred_million
    ) {
      if (params?.recapInfo?.gender === GENDER.M) {
        rs.icon = 1;
        rs.name = "Ông Hoàng Đầu tư";

      } else if (params?.recapInfo?.gender === "F") {
        rs.icon = 1;
        rs.name = "Nữ hoàng Đầu tư";

      } else {
        rs.icon = 1;
        rs.name = "Chiến Thần Đầu tư";
      }
    }
    else if (params.recapInfo.totalSavingInvestmentAmount >= fifty_million) {
      if (params?.recapInfo?.gender === GENDER.M) {
        rs.icon = 2;
        rs.name = "Nam Triệu Phú";
      } else if (params?.recapInfo?.gender === "F") {
        rs.icon = 2;
        rs.name = "Nữ Triệu Phú";
      } else {
        rs.icon = 2;
        rs.name = "Chiến Thần Đầu tư";
      }
    }
    else if (params.recapInfo.totalFxTransaction >= RULE_Transaction) {
      rs.icon = 3;
      rs.name = "Ngôi Sao Ngoại Tệ";
    }
    else if (params.recapInfo.totalMbalInsurance >= RULE_INSUARANCE) {
      if (params?.recapInfo?.gender === GENDER.M) {
        rs.icon = 4;
        rs.name = "Chiến Thần Bảo Hiểm";
      } else if (params?.recapInfo?.gender === "F") {
        rs.icon = 4;
        rs.name = "Hoa Hậu Bảo Hiểm";
      } else {
        rs.icon = 4;
        rs.name = "Chiến Thần Bảo Hiểm";
      }
    }
    else if (
      params.recapInfo.totalCreditCardTransactionAmount >= RULE_INSUARANCE
    ) {
      if (params?.recapInfo?.gender === GENDER.M) {
        rs.icon = 5;
        rs.name = "Hoàng tử Trendy";
      } else if (params?.recapInfo?.gender === "F") {
        rs.icon = 5;
        rs.name = "Tiểu thư Trendy";
      } else {
        rs.icon = 5;
        rs.name = "Ngôi Sao Trendy";
      }
    }

    return rs;
  };
  const renderTextField = (str: string) => {
    let rsTextField: any = str?.split("-");
    let rs = {
      name: "",
      value: Number(rsTextField?.[1]),
      unit: "",
      fontSize: "82px",
    };
    if (rsTextField?.[0] == ZINDEX_RENDER[2]) {
      rs.name = "Đồng hành cùng MB trong suốt";
      rs.unit = "ngày";
    } else if (rsTextField?.[0] == ZINDEX_RENDER[3]) {
      rs.name = "Tổng số lượt giao dịch";
      rs.unit = "lượt";
    } else if (rsTextField?.[0] == ZINDEX_RENDER[4]) {
      rs.name = "Tổng số tiền giao dịch";
      rs.unit = "đồng";
      rs.fontSize = "40px";
    } else if (rsTextField?.[0] == ZINDEX_RENDER[5]) {
      rs.name = "Tổng số lượt giao dịch thẻ tín dụng";
      rs.unit = "lượt";
    } else if (rsTextField?.[0] == ZINDEX_RENDER[6]) {
      rs.name = "Tổng số tiền giao dịch thẻ tín dụng";
      rs.unit = "đồng";
      rs.fontSize = "40px";
    } else if (rsTextField?.[0] == ZINDEX_RENDER[7]) {
      rs.name = "Tổng số tiền hóa đơn đã thanh toán";
      rs.unit = "đồng";
      rs.fontSize = "40px";
    } else if (rsTextField?.[0] == ZINDEX_RENDER[8]) {
      rs.name = "MB trợ vốn cho Quý khách";
      rs.unit = "đồng";
      rs.fontSize = "40px";
    } else if (rsTextField?.[0] == ZINDEX_RENDER[9]) {
      rs.name = "Tổng số tiền gửi và đầu tư";
      rs.unit = "đồng";
      rs.fontSize = "40px";
    } else if (rsTextField?.[0] == ZINDEX_RENDER[10]) {
      rs.name = "Sức khỏe đang được bảo vệ";
      rs.unit = "đồng";
      rs.fontSize = "40px";
    } else if (rsTextField?.[0] == ZINDEX_RENDER[11]) {
      rs.name = "Tổng số tiền giao dịch ngoại hối";
      rs.unit = "đồng";
      rs.fontSize = "60px";
    }
    //
    return rs;
  };
  if (valueCurrent?.split("-")[0] == 0) {
    return (
      <>
        {" "}
        <div
          className="absolute w-100 text-center responsive-logo-thanks-img"
          style={{ padding: "0px 12px", top: "25%", zIndex: 5 }}
        >
          <div style={{ minHeight: "86px" }}>
            <img
              className="item-slider-text responsive-logo-thanks"
              src={text_thanks_customer_png}
            />
          </div>
          <div style={{ minHeight: "60px", margin: "12px 0px" }}>
            <img src={icon_celebration_thanks} />
          </div>
          <div
            className="item-slider-text text-averta-standard responsive-text-fs-18"
            style={{ color: "white", fontWeight: 600, fontSize: "23px", animationPlayState: statePause ? "running" : "paused" }}
          >
            {/* Đã luôn gắn bó và sử dụng dịch vụ của MB trong suốt thời gian qua */}
            {
              showContent ? <>
                <label style={{ animationPlayState: statePause ? "running" : "paused" }}>Đã</label>
                <label style={{ animationPlayState: statePause ? "running" : "paused" }}>luôn</label>
                <label style={{ animationPlayState: statePause ? "running" : "paused" }}>gắn</label>
                <label style={{ animationPlayState: statePause ? "running" : "paused" }}>bó</label>
                <label style={{ animationPlayState: statePause ? "running" : "paused" }}>và</label>
                <label style={{ animationPlayState: statePause ? "running" : "paused" }}>sử</label>
                <label style={{ animationPlayState: statePause ? "running" : "paused" }}>dụng</label>
                <label style={{ animationPlayState: statePause ? "running" : "paused" }}>dịch vụ</label>
                <label style={{ animationPlayState: statePause ? "running" : "paused" }}>của</label>
                <label style={{ animationPlayState: statePause ? "running" : "paused" }}>MB</label>
                <label style={{ animationPlayState: statePause ? "running" : "paused" }}>trong</label>
                <label style={{ animationPlayState: statePause ? "running" : "paused" }}>suốt</label>
                <label style={{ animationPlayState: statePause ? "running" : "paused" }}>thời</label>
                <label style={{ animationPlayState: statePause ? "running" : "paused" }}>gian</label>
                <label style={{ animationPlayState: statePause ? "running" : "paused" }}>qua</label>
              </> : ''

            }


          </div>
        </div>
      </>
    );
  } else if (valueCurrent?.split("-")[0] == 1) {
    return (
      <>
        <div
          className="absolute w-100 text-center text-averta-standard"
          style={{ top: "0px", zIndex: 5 }}
        >
          <div style={{ minHeight: "284px" }}>
            <img
              className=" w-100 responsive-logo-medal max-height-348"
              src={image_medal}
            />
          </div>
          <div style={{ minHeight: "206px" }}>
            <img
              className=" relative responsive-logo-medal-details"
              style={{ top: "-90px" }}
              src={arrImageMedal[renderTextInvest(fakeData).icon]}
            />
          </div>
          <div
            className="relative responsive-logo-medal-details-text "
            style={{ top: "-68px", color: "white", fontWeight: 600 }}
          >
            <div
              className="item-slider-text responsive-text-fs-18"
              style={{ fontSize: "18px", fontWeight: 600 }}
            >
              Chúc mừng quý khách đã trở thành
            </div>
            <div>
              <div
                className="item-slider-text item-slider-text-scale-text responsive-text-fs-23 text-scale-2"
                style={{
                  fontSize: "32px",
                  fontWeight: 800,
                  textTransform: "uppercase",
                  animationPlayState: statePause ? "running" : "paused"
                }}
              >
                {renderTextInvest(fakeData).name}
              </div>
            </div>
            <div
              className="item-slider-text responsive-text-fs-23 text-scale-2"
              style={{ fontSize: "32px", fontWeight: 800, animationPlayState: statePause ? "running" : "paused" }}
            >
              2022
            </div>
          </div>
        </div>
      </>
    );
  } else if (valueCurrent?.split("-")[0] == 12) {
    return (
      <div
        className="absolute w-100 text-center "
        style={{
          padding: "0px 12px",
          top: "40%",
          zIndex: 5,
          transform: "translateY(-40%)",
        }}
      >
        <div
          style={{ minHeight: "32px", margin: "0px 0px 30px 0px" }}>
          <img src={ic_fire_work_thanks} style={{}} />
        </div>

        <div
          className="item-slider-text text-averta-standard"
          style={{
            color: "white",
            fontWeight: 600,
            fontSize: "22px",
            height: '100px'

          }}
        >
          {/* Kính chúc Quý khách và gia đình <br /> năm mới nhiều sức khỏe, <br />
          hạnh phúc và an khang thịnh vượng! */}


          {
            showContent ? <>
              <span style={{ animationPlayState: statePause ? "running" : "paused" }}>Kính</span>
              <span style={{ animationPlayState: statePause ? "running" : "paused" }}>chúc</span>
              <span style={{ animationPlayState: statePause ? "running" : "paused" }}>Quý</span>
              <span style={{ animationPlayState: statePause ? "running" : "paused" }}>khách</span>
              <span style={{ animationPlayState: statePause ? "running" : "paused" }}>và</span>
              <span style={{ animationPlayState: statePause ? "running" : "paused" }}>gia</span>
              <span style={{ animationPlayState: statePause ? "running" : "paused" }}>đình</span><br />
              <span style={{ animationPlayState: statePause ? "running" : "paused" }}>năm</span>
              <span style={{ animationPlayState: statePause ? "running" : "paused" }}>mới</span>
              <span style={{ animationPlayState: statePause ? "running" : "paused" }}>nhiều</span>
              <span style={{ animationPlayState: statePause ? "running" : "paused" }}>sức</span>
              <span style={{ animationPlayState: statePause ? "running" : "paused" }}>khỏe,</span><br />
              <span style={{ animationPlayState: statePause ? "running" : "paused" }}>hạnh</span>
              <span style={{ animationPlayState: statePause ? "running" : "paused" }}>phúc</span>
              <span style={{ animationPlayState: statePause ? "running" : "paused" }}>và</span>
              <span style={{ animationPlayState: statePause ? "running" : "paused" }}>an</span>
              <span style={{ animationPlayState: statePause ? "running" : "paused" }}>khang</span>
              <span style={{ animationPlayState: statePause ? "running" : "paused" }}>thịnh</span>
              <span style={{ animationPlayState: statePause ? "running" : "paused" }}>vượng!</span>
            </> : ''
          }


        </div>

      </div>
    );
  } else {
    return (
      <div
        className="absolute w-100 text-center responsive-frame"
        style={{
          top: "50%",
          transform: "translateY(-50%)",
          padding: "0px 12px",
          zIndex: 5,
        }}
      >
        <div className="module-border-wrap">
          <div className="module">
            <img
              className="module-img"
              src={icon_celebration}
              style={{ margin: "24px 0px 20px 0px" }}
            />

            <div>
              <div
                className="w-100 text-center"
                style={{
                  color: "#93A6B5",
                  fontSize: "20px",
                  fontWeight: 600,
                  padding: "0px",
                }}
              >
                {renderTextField(valueCurrent).name}
              </div>
              <div
                className="animate-charcter"
                style={{
                  fontSize: handleCalculatorFontSize(
                    formatCurrentComma(renderTextField(valueCurrent).value)
                      ?.length
                  ),
                  fontWeight: 700,
                  lineHeight: "98px",
                  animationPlayState: statePause ? "running" : "paused",
                  // animationPlayState: "paused",
                }}
              >
                {formatCurrentComma(renderTextField(valueCurrent).value)}
              </div>
              <div
                className="relative"
                style={{
                  fontSize: "26px",
                  top: "-12px",
                  color: "white",
                  fontFamily: "Chakra Petch",
                  fontWeight: 700,
                }}
              >
                {renderTextField(valueCurrent).unit}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
