import { BrowserRouter } from "react-router-dom";
import "./App.css";
import "./Responsive.css";
import "./AnimationText.css";

import { useEffect, useState } from "react";
import BirthDayCustomer from "./pages/Home/BirthDayCustomer";
import { getInfo, getToken } from "./api/app";
import { closeWebview } from "./api/common";
import queryString from "query-string";
import "bootstrap/dist/css/bootstrap.min.css";
export const App = () => {
  const [data, setData] = useState<any>(undefined);

  useEffect(() => {
    getAccountInfo().then();
  }, []);
  async function getAccountInfo() {
    // get user info
    const getInfoToken: any = await getToken();
    var refNo = new Date().getTime();
    var sessionId = queryString.parse(window.location.search).sessionId;
    if (!sessionId) {
      $("#exampleModalCenter").modal("show");
      setData(undefined);
    } else {
      if (getInfoToken?.status == 200) {
        try {
          sessionStorage.setItem(
            "access_token",
            getInfoToken?.data?.access_token
          );
          const getInfoRef: any = await getInfo(refNo, sessionId);
          if (getInfoRef?.status == 200 && getInfoRef?.data?.recapInfo) {
            setData(getInfoRef?.data);
            $("#exampleModalCenter").modal("hide");
          } else {
            $("#exampleModalCenter").modal("show");
            setData(undefined);
          }
        } catch {
          $("#exampleModalCenter").modal("show");
          setData(undefined);
        }
      } else {
        $("#exampleModalCenter").modal("show");
        setData(undefined);
      }
    }
  }
  const handleCloseWebview = () => {
    $("#exampleModalCenter").modal("hide");
    closeWebview();
  };
  return (
    <>
      <div
        className="modal fade"
        id="exampleModalCenter"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Thông báo
              </h5>
              <button
                style={{
                  fontSize: "26px",
                  background: "transparent",
                  border: "unset",
                }}
                onClick={() => {
                  handleCloseWebview()
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <div aria-hidden="true">&times;</div>
              </button>
            </div>
            <div className="modal-body">Không lấy được thông tin tài khoản</div>
            <div className="modal-footer">
              <button
                onClick={() => {
                  handleCloseWebview()
                }}
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Đóng
              </button>
            </div>
          </div>
        </div>
      </div>
      <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
        {data != undefined ? <BirthDayCustomer data={data} /> : ""}
      </BrowserRouter>
    </>
  );
};
