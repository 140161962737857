import { useEffect, useLayoutEffect, useRef, useState } from "react";
import icon_share_facebook_customer from "../../assets/images/icon_share_facebook_customer.svg";
import html2canvas from "html2canvas";
import ControlsSlide from "./ControlsSlide";
import DotSlide from "./DotSlide";
import CRenderContent from "./CRenderContent";
import { shareImage } from "../../api/common";
import {
  fifty_million,
  RULE_INSUARANCE,
  RULE_Transaction,
} from "./RenderContent";
export const formatCurrentComma = (currency: any) => {
  if (currency) {
    return String(currency)?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
};
let timeOut1: any;
let timeOut2: any;
let timeOut3: any;
let clear: any;
let clear1: any;
let clear2: any;
let clearTimeOutAnimation: any
let clearTimeOutvalue: any
export const TIME_ANIMATION = 700;
export const TIME_ANIMATION_NEXT_PAGE = 5000;
const TIME_ANIMATION_TEXT = 3000;

export const GENDER = {
  F: "F",
  M: "M",
};
export const ZINDEX_RENDER = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  10: 10,
  11: 11,
};
const BirthDayCustomer = ({ data }: any) => {
  const fakeData = data;
  let dataSlide: any = [
    [
      {
        name: 0,
        index: 0,
        val: "start_slide",
      },
    ],
    [
      {
        name: 0,
        index: 12,
        val: "endSlide",
      },
    ],
  ];
  const {
    totalSavingInvestmentAmount,
    totalOnboardDay,
    totalFundTransferTransaction,
    totalFundTransferTransactionAmount,
    totalCreditCardTransaction,
    totalCreditCardTransactionAmount,
    totalBillAmount,
    totalLoan,
    cyberRiskAmount,
    totalFxTransaction,
    totalMicInsurance,
    totalMbalInsurance

  } = fakeData?.recapInfo;
  if (
    totalSavingInvestmentAmount >= fifty_million ||
    totalFxTransaction >= RULE_Transaction ||
    totalMbalInsurance >= RULE_INSUARANCE ||
    totalCreditCardTransactionAmount >= RULE_INSUARANCE
  ) {
    let rs = "totalSavingInvestmentAmount";
    let rsName = totalSavingInvestmentAmount;
    if (totalSavingInvestmentAmount) {
      rs = "totalSavingInvestmentAmount";
      rsName = totalSavingInvestmentAmount;
    } else if (totalFxTransaction) {
      rs = "totalFxTransaction";
      rsName = totalFxTransaction;
    } else if (totalMbalInsurance) {
      rs = "totalMbalInsurance";
      rsName = totalMbalInsurance;
    } else if (totalCreditCardTransactionAmount) {
      rs = "totalCreditCardTransactionAmount";
      rsName = totalCreditCardTransactionAmount;
    }

    dataSlide.push([{ name: rsName, index: ZINDEX_RENDER[1], val: rs }]);
  }
  if (totalOnboardDay) {
    dataSlide.push([
      {
        name: totalOnboardDay,
        index: ZINDEX_RENDER[2],
        val: "totalOnboardDay",
      },
    ]);
  }
  if (totalFundTransferTransaction) {
    dataSlide.push([
      {
        name: totalFundTransferTransaction,
        index: ZINDEX_RENDER[3],
        val: "totalFundTransferTransaction",
      },
    ]);
  }
  if (totalFundTransferTransactionAmount) {
    dataSlide.push([
      {
        name: totalFundTransferTransactionAmount,
        index: ZINDEX_RENDER[4],
        val: "totalFundTransferTransactionAmount",
      },
    ]);
  }
  if (totalCreditCardTransaction) {
    dataSlide.push([
      {
        name: totalCreditCardTransaction,
        index: ZINDEX_RENDER[5],
        val: "totalCreditCardTransaction",
      },
    ]);
  }
  if (totalCreditCardTransactionAmount) {
    dataSlide.push([
      {
        name: totalCreditCardTransactionAmount,
        index: ZINDEX_RENDER[6],
        val: "totalCreditCardTransactionAmount",
      },
    ]);
  }
  // if (totalSavingInvestmentAmount1) {
  //     dataSlide.push([{ name: totalSavingInvestmentAmount1, index: 7, val: 'totalSavingInvestmentAmount' }])
  // }
  if (totalBillAmount) {
    dataSlide.push([
      {
        name: totalBillAmount,
        index: ZINDEX_RENDER[7],
        val: "totalBillAmount",
      },
    ]);
  }

  if (totalLoan) {
    dataSlide.push([
      { name: totalLoan, index: ZINDEX_RENDER[8], val: "totalLoan" },
    ]);
  }
  if (totalSavingInvestmentAmount) {
    dataSlide.push([
      {
        name: totalSavingInvestmentAmount,
        index: ZINDEX_RENDER[9],
        val: "totalSavingInvestmentAmount",
      },
    ]);
  }
  if (totalMbalInsurance) {
    dataSlide.push([
      {
        name: totalMbalInsurance,
        index: ZINDEX_RENDER[10],
        val: "totalMbalInsurance",
      },
    ]);
  }
  if (totalFxTransaction) {
    dataSlide.push([
      {
        name: totalFxTransaction,
        index: ZINDEX_RENDER[11],
        val: "totalFxTransaction",
      },
    ]);
  }
  dataSlide = dataSlide.sort((itemSort: any, itemSort1: any) => {
    return itemSort?.[0]?.index - itemSort1?.[0]?.index;
  }); // sắp xếp index của slide
  const [statePause, setStatePause] = useState(true);
  const container = useRef<any>(null); //truy cập vào Dom để lấy ra hình ảnh
  const [valueCurrent, setValueCurrent] = useState("");
  const [valueCurrentNext, setValueCurrentNext] = useState("");
  const [showSlideBirthDay, setShowSlideBirthDay] = useState(true);
  const [hiddenDot, setHiddenDots] = useState(false);
  const [checkShare, setCheckShare] = useState(false)
  const annimationNext = (value: any) => {
    setValueCurrentNext(value as any);
    const itemSlide = Array.from(
      document.querySelectorAll(".item-slider-right")
    );

    const itemSlideFade = Array.from(
      document.querySelectorAll(".item-slider-right-fade")
    );
    itemSlide[0]?.classList.remove("pt-page-rotateFoldLeft");
    itemSlideFade[0]?.classList.remove("pt-page-moveFromRightFade");
    const itemSlideText = Array.from(
      document.querySelectorAll(".animate-charcter")
    );
    itemSlideText.map((el) => {
      el?.classList.remove("animate-charcter-animation");
    });

    itemSlide[0]?.classList.add("pt-page-rotateFoldLeft");
    itemSlideFade[0]?.classList.add("pt-page-moveFromRightFade");
    timeOut1 = setTimeout(() => {
      itemSlide[0]?.classList.remove("pt-page-rotateFoldLeft");
      itemSlideFade[0]?.classList.remove("pt-page-moveFromRightFade");
      setValueCurrent(value);
      const itemSlideText = Array.from(
        document.querySelectorAll(".animate-charcter")
      );
      itemSlideText.map((el) => {
        el?.classList.add("animate-charcter-animation");
      });
    }, TIME_ANIMATION);
    timeOut2 = setTimeout(() => {

    }, TIME_ANIMATION);
  };
  useEffect(() => {
    const progress = Array.from(document.querySelectorAll(".progress"));
    const progressActive = Array.from(
      document.querySelectorAll(".progress.active")
    );
    const itemSlide = Array.from(
      document.querySelectorAll(".item-slider-right")
    );
    const itemSlideFade = Array.from(
      document.querySelectorAll(".item-slider-right-fade")
    );

    const playNext = (e: any) => {
      const current = e && e.target;
      let next: any;
      if (current) {
        const currentIndex = progress.indexOf(current);
        if (currentIndex < progress.length) {
          next = progress[currentIndex + 1];
        }
        for (var i = 0; i <= progress.length - 1; i++) {
          if (i <= currentIndex) {
            progress[i].classList.add('passed')
          }
        }
        progress[currentIndex]?.classList.remove("active");
        if (next?.getAttribute("value")) {
          annimationNext(next?.getAttribute("value"));
        }
      }
      if (!next) {
        itemSlide[0]?.classList.remove("pt-page-rotateFoldLeft");
        itemSlideFade[0]?.classList.remove("pt-page-moveFromRightFade");
        next = progress[progress.length - 1];
      }
      next.classList.add("active");
      return () => {
        CClearTimeOut();
      };
    };
    progress.map((el) => el.addEventListener("animationend", playNext, false));

    return () => {
      CClearTimeOut();
    };
  }, []);

  const [imgSrc, setImgSrc] = useState("");
  const handleScreenShot = () => {
    setStatePause(false)
    setCheckShare(true)
    setTimeout(() => {
      if (document.readyState === 'complete') {
        const itemSlideText = Array.from(
          document.querySelectorAll(".animate-charcter")
        );
        const itemSpan = Array.from(
          document.getElementsByTagName("span")
        );
        const itemLabel = Array.from(
          document.getElementsByTagName("label")
        );
        itemSpan.map((itemMap) => {
          itemMap.style.opacity = 1 as any
        })
        itemLabel.map((itemMap) => {
          itemMap.style.opacity = 1 as any
        })

        itemSlideText.map((el) => {
          el?.classList.remove("animate-charcter-animation");
        });
        if (container) {
          window.scrollTo(0, 0);
          html2canvas(container?.current, {width: container?.current.scrollWidth,
            height: container?.current.scrollHeight, scale: 0.8}).then(async (canvas: any) => {
            const file = canvas.toDataURL();
            setTimeout(() => {
              setCheckShare(false)
              itemSpan.map((itemMap) => {
                itemMap.style.opacity = 0 as any
              })
              itemLabel.map((itemMap) => {
                itemMap.style.opacity = 0 as any
              })
            },3000)
            await shareImage(file);
          });
        }
      }
    }, 500)
  };
  const CClearTimeOut = () => {
    clearTimeout(timeOut1);
    clearTimeout(timeOut2);
    clearTimeout(timeOut3);
    // clearTimeout(clear);
    // clearTimeout(clear1);
    // clearTimeout(clear2);
    clearTimeout(clearTimeOutAnimation)
    clearTimeout(clearTimeOutvalue)

  };
  // console.log(dataSlide);
  const activeAnimationWhenChangeDot = (state: string, value?: any) => {
    setStatePause(true);
    CClearTimeOut();
    const progressActive: any = Array.from(
      document.querySelectorAll(".progress.active")
    );
    const progress = Array.from(
      document.querySelectorAll(".progress")
    );

    clearTimeOutAnimation = setTimeout(() => {
      progress.map((itemMap1) => {
        itemMap1.classList.remove("active");
        itemMap1.classList.remove("passed");
      });
      if (state === "NEXT") {
        progressActive[0]?.nextElementSibling?.classList?.add("active");
      }
      else if (state === "PRE") {
        progressActive[0]?.previousElementSibling?.classList?.add("active");
      }
      else if (state === "") {
        progress[value?.split('-')?.[0]]?.classList?.add("active");
      }
      for (var j = 0; j <= Number(value?.split('-')?.[0]) - 1; j++) {
        progress[j].classList.add("passed");
      }
      // clearTimeout(clearTimeOutAnimation)
    }, TIME_ANIMATION);
    annimationNext(value);
  };
  return showSlideBirthDay ? (
    <>
      {/* <img src={imgSrc} /> */}
      <button
        disabled={checkShare}
        onClick={handleScreenShot}
        className="absolute"
        style={{
          bottom: "0px",
          zIndex: 20,
          left: "50%",
          transform: "translateX(-50%)",
          background: 'transparent',
          border: 'unset'
        }}>
        <img
          src={icon_share_facebook_customer}

        />
      </button>
      <div className="relative pt-perspective" id="capture">

        <div
          className="item-slider-right"
          ref={container}
          style={{ height: '100vh', zIndex: 4 }}
        >
          <CRenderContent
            statePause={statePause}
            fakeData={fakeData}
            valueCurrent={valueCurrent}
            showContent={true}
          />
        </div>
        {
          <div
            className="item-slider-right-fade absolute w-100"
            style={{ top: "0px", zIndex: -1, height: '100vh' }}
          >
            <CRenderContent
              statePause={statePause}
              fakeData={fakeData}
              valueCurrent={valueCurrentNext}
              showContent={false}
            />
          </div>
        }
        <div>
          {!hiddenDot ? (
            <>
              <DotSlide
                activeAnimationWhenChangeDot={activeAnimationWhenChangeDot}
                setShowSlideBirthDay={setShowSlideBirthDay}
                dataSlide={dataSlide}
                setValueCurrent={setValueCurrent}
                valueCurrent={valueCurrent}
                statePause={statePause}
                setValueCurrentNext={setValueCurrentNext}
              />
              <ControlsSlide
                activeAnimationWhenChangeDot={activeAnimationWhenChangeDot}
                setStatePause={setStatePause}
                setValueCurrent={setValueCurrent}
                statePause={statePause}
              />
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};
export default BirthDayCustomer;
