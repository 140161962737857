import axios from "axios";

const DEFAULT_TIMEOUT = 60000;
export const DEFAULT_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const getHeaders = () => {
    return {
        "Content-Type": "application/json;charset=UTF-8",
        "Authorization": `Bearer ${sessionStorage.getItem('access_token')}`,
        'context': window.location.pathname
    };
};

export const get = async (requestParams, options) => {
    if (window.navigator.onLine) {
        return await axios.get(requestParams.url, {
            params: requestParams.params,
            baseURL: requestParams.baseURL || DEFAULT_BASE_URL,
            timeout: options?.timeout || DEFAULT_TIMEOUT,
            headers: requestParams.headers || getHeaders(),
        });
    } else {
        return { data: { code: -1, message: "Mất kết nối Internet. Vui lòng kiểm tra lại đường truyền." } };
    }
};

//x-ms-blob-type:BlockBlob
export const post = async (requestParams, options) => {
    if (window.navigator.onLine) {
        return await axios.post(requestParams.url, requestParams.params, {
            baseURL: requestParams.baseURL || DEFAULT_BASE_URL,
            timeout: options?.timeout || DEFAULT_TIMEOUT,
            headers: requestParams.headers || getHeaders(),
        });
    } else {
        return { data: { code: -1, message: "Mất kết nối Internet. Vui lòng kiểm tra lại đường truyền." } };
    }
};

export const deleteAPI = async (requestParams, options) => {
    if (window.navigator.onLine) {
        return await axios.delete(requestParams.url, {
            params: requestParams.params,
            baseURL: requestParams.baseURL || DEFAULT_BASE_URL,
            timeout: options?.timeout || DEFAULT_TIMEOUT,
            headers: requestParams.headers || getHeaders(),
        });
    } else {
        return { data: { code: -1, message: "Mất kết nối Internet. Vui lòng kiểm tra lại đường truyền." } };
    }
};

//share image base 64
export const shareImage = (file) => {
    if (window?.ReactNativeWebView) {
        window?.ReactNativeWebView?.postMessage(
            JSON.stringify({
                type: "slides_year_in_review",
                image: file,
            })
        );
    }
    else{
        window.postMessage(
            JSON.stringify({
                type: "slides_year_in_review",
                image: file,
            })
        );
    }
}

//close miniapp
export const closeWebview = () => {
    if (window?.ReactNativeWebView) {
        window?.ReactNativeWebView?.postMessage(
            JSON.stringify({
                type: "go_back",
                source: "web_mobile",
            })
        );
    }
};
