import logo_mb_prio from "../../assets/images/logo_mb_private.svg";
import logo_mb_mass from "../../assets/images/logo_mb_mass.svg";
import logo_mb_private from "../../assets/images/logo_mb_prio.svg";

import bg_orange from "../../assets/images/bg_orange.png";
import image_slap_png from "../../assets/images/image_slap_png.png";
import image_footer_thanks_png from "../../assets/images/image_footer_thanks_png.png";
import image_people_cel_png from "../../assets/images/image_people_cel_png.png";
import imgBanner from '../../assets/images/bg_tri_an_khach_hang.svg'
import RenderContent from "./RenderContent";
interface ICrenderContent {
  valueCurrent: any;
  fakeData: any;
  statePause: boolean;
  showContent: boolean;
}
const height = window.innerHeight;

export default function CRenderContent({
  valueCurrent,
  fakeData,
  statePause,
  showContent,
}: ICrenderContent) {
  return (
    <div className="relative" style={{ background: "#0b1c2b", height: `${height}px` }}>
      {/* <div className="bg"
      style={{backgroundImage:`url("${imgBanner}")`}}
      //    style={{ height: `${height}px` }}
      >
      </div> */}
      <img src={imgBanner} style={{ height: `${height}px` }} />
      {valueCurrent?.split("-")[0] != 1 ? (
        <div
          className="absolute w-100 text-center responsive-logo"
          style={{ top: "15%", minHeight: "36px" }}
        >
          <img
            src={
              fakeData.recapInfo.sector == "MASS"
                ? logo_mb_mass
                : fakeData.recapInfo.sector == "PRIVATE"
                  ? logo_mb_private
                  : logo_mb_prio
            }
          />
        </div>
      ) : (
        ""
      )}
      {
        <div>
          {
            <>
              <RenderContent
                showContent={showContent}
                statePause={statePause}
                show={true}
                fakeData={fakeData}
                valueCurrent={valueCurrent}
              />
            </>
          }

          <div className="absolute w-100" style={{ bottom: "0" }}>
            {
              valueCurrent?.split("-")[0] != 1 &&
                valueCurrent?.split("-")[0] != 0 ? (
                <div className="absolute w-100" style={{ bottom: "0" }}>
                  <img className="w-100 max-height-230" src={bg_orange} />
                </div>
              ) : (
                ""
              )
            }
            <div className="absolute w-100" style={{ bottom: "0", zIndex: 3 }}>

              {
                valueCurrent?.split("-")[0] == 0 ? <img
                  className="w-100 max-height-230"
                  src={image_footer_thanks_png}
                /> : ''
              }
              {
                valueCurrent?.split("-")[0] == 1 ? <img
                  className="w-100 max-height-230"
                  src={image_slap_png}
                /> : ''
              }
              {
                valueCurrent?.split("-")[0] != 0 && valueCurrent?.split("-")[0] != 1 ? <img
                  className="w-100 max-height-230"
                  src={image_people_cel_png}
                /> : ''
              }
            </div>
          </div>
        </div>
      }
    </div>
  );
}
